<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('curriculums')"
                        :isNewButton="checkPermission('curriculum_store')"
                        @new-button-click="createFormShow"

                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('curriculums')"
                              :isNewButton="checkPermission('curriculum_store')"
                              @new-button-click="createFormShow"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                :export-excel="true"
                @exportExcel="exportExcelAll"
                @filterClear="filterClear"
            >
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"
                            ></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                :faculty_code="datatable.queryParams.filter.faculty_code"
                                v-model="datatable.queryParams.filter.program_code"
                            ></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="datatable.queryParams.filter.status"
                            ></status-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable
                :isLoading.sync="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                :queryParams="datatable.queryParams"
                :lineNumbers="false"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="createFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                         v-if="checkPermission('curriculum_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="updateFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear"
                         v-if="checkPermission('curriculum_update')">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toLocaleUpperCase('tr-TR') }}
                </template>
                <template v-slot:CommonModalContent>
                    <update-form :formId="formId"
                                 @updateFormSuccess="updateFormSuccess"
                                 v-if="formProcess=='update'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import CurriculumService from "@/services/CurriculumService";
import CommonModal from "@/components/elements/CommonModal";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";

import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';
import ReservationService from "@/services/ReservationService";
import CustomerPriceService from "@/services/CustomerPriceService";

export default {
    components: {
        ProgramSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        CommonModal,
        StatusSelectbox,
        FacultySelectbox,
        CreateForm,
        UpdateForm
    },
    metaInfo() {
        return {
            title: this.$t("curriculum"),
        };
    },
    data() {
        return {
            id: 0,
            formId: null,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "curriculum_update",
                                callback: (row) => {
                                    this.updateFormShow(row.id);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "curriculum_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },

                            {
                                text: this.$t("slots"),
                                class: "ri-list-check",
                                permission: "slot_index",
                                callback: (row) => {
                                    this.$router.push("/slots/" + row.id);
                                },
                            },
                            {
                                text: this.$t("excel"),
                                class: "ri-upload-line",
                                callback: ({id}) => {
                                    this.getRowsExcel(id)

                                },
                            },
                            {
                                text: this.$t("do_copy"),
                                class: "ri-file-copy-2-line",
                                permission: "curriculum_delete",
                                callback: (row) => {
                                    this.copy(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: "id",
                        sortable: true,
                    },
                    {
                        label: this.$t("program_code"),
                        field: "program_code",
                        sortable: true,
                    },
                    {
                        label: this.$t("program_name"),
                        field: "program_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("program_name_en"),
                        field: "program_name_en",
                        sortable: false,
                    },
                    {
                        label: this.$t("faculty_name"),
                        field: "faculty_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("faculty_name_en"),
                        field: "faculty_name_en",
                        sortable: false,
                    },
                    {
                        label: this.$t("academic_year"),
                        field: "academic_year",
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return CurriculumService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        delete(id) {
            this.deleteModal(() => {
                CurriculumService.deleteCurriculum(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        copy(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_copy'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
            .then((result) => {
                if (result.isConfirmed) {
                    CurriculumService.copy(id)
                        .then((response) => {
                            this.filter();
                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t("api." + error.data.message));
                        });
                }
            })
        },
        async createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        updateFormShow(id) {
            this.formId = id
            this.formProcess = 'update'
            this.$refs.updateFormModal.$refs.commonModal.show()
        },
        async updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        createFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'create'
            this.$refs.createFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        getRowsExcel(id) {
            return CurriculumService.exportExcel(id)
                .then((response) => {
                    this._downloadFile(response, this.$t('curriculums') + '.xlsx')
                })
                .catch(err => this.showErrors(err))
        },
        exportExcelAll(){
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            CurriculumService.exportExcelAll(config)
                .then(response => {
                    this.$toast.success(this.$t(response.data.message));
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch(err => {
                    this.showErrors(err)
            })
        }
    },
};
</script>
