<template>
    <div class="row mb-5 align-items-center">
        <div class="col-12">
            <ValidationObserver ref="storeForm">
                <div class="row">
                    <div class="col-6">
                        <ValidationProvider
                            name="faculty"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-form-group :label="$t('faculty')">
                                <faculty-selectbox
                                    v-model="form.faculty_code"
                                    :validate-error="errors[0]"
                                ></faculty-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <ValidationProvider
                            name="program"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-form-group :label="$t('program')">
                                <program-selectbox
                                    v-model="form.program_code"
                                    :faculty_code="form.faculty_code"
                                    :validate-error="errors[0]"
                                ></program-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <ValidationProvider
                            name="academic_year"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-form-group :label="$t('academic_year')">
                                <academic-years-selectbox
                                    v-model="form.academic_year"
                                    :validate-error="errors[0]"
                                ></academic-years-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <ValidationProvider
                            name="status"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-form-group :label="$t('status')">
                                <status-selectbox
                                    v-model="form.status"
                                    :validate-error="errors[0]"
                                ></status-selectbox>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="col-12 mt-3 d-flex">
            <b-button
                @click="createForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </div>
    </div>
</template>

<script>
//Component
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
//Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
//Service
import CurriculumService from "@/services/CurriculumService";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        ProgramSelectbox,
        FacultySelectbox,
        StatusSelectbox,
        AcademicYearsSelectbox

    },
    data() {
        return {
            formLoading: false,
            form: {
                city_id: null,
                district_code: null,
                name: null,
            }
        }
    },
    methods: {
        async createForm() {
            const isValid = await this.$refs.storeForm.validate()
            if (isValid) {
                CurriculumService.store(this.form)
                    .then((response) => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$emit("createFormSuccess")
                    })
                    .catch((error) => {
                        this.showErrors(error, this.$refs.storeForm)
                    });
            }
        }
    }
}
</script>
